<template>
  <div v-if="isLoading" class="absolute inset-0 flex justify-center items-center bg-white">
    <icon-loading class="w-6 h-6 text-blumine" />
  </div>

  <form v-else @submit.prevent="create">

    <div class="bg-white shadow-sm rounded-md">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">info</span>
          <h2 class="text-lg font-bold ml-1">General</h2>
        </div>
      </div>

      <div class="relative">
        <errors :errors="state.errors" class="mb-4" />
        <div class="p-4 xl:p-6">
          <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 xl:space-x-6">
            <div class="w-full md:w-1/2 flex flex-col space-y-2">
              <text-input label="Location Id" v-model="state.form.location_id" :required="true" :disabled="cannotEditField('location_id')" />
              <text-input label="Mac Address" v-model="state.form.mac" :required="true" :disabled="cannotEditField('mac')" />
              <text-input label="Name" v-model="state.form.name" :required="true" :disabled="cannotEditField('name')" />

              <select-box label="Timezone" v-model="state.form.timezone" :disabled="cannotEditField('timezone')">
                <option :value="null">Select Timezone</option>
                <option v-for="option in timezoneOptions" :key="option" :value="option">
                  {{ option }}
                </option>
              </select-box>

              <select-box label="Machine Model" :required="true" v-model="state.form.model" :disabled="cannotEditField('model')">
                <option :value="null">Select Model</option>
                <option v-for="option in machineModels" :key="option" :value="option">
                  {{ option }}
                </option>
              </select-box>
            </div>
            <div class="w-full md:w-1/2 flex flex-col space-y-2">
              <text-input label="Address" v-model="state.form.address_address" :disabled="cannotEditField('address_address')" />
              <text-input label="City" v-model="state.form.address_city" :disabled="cannotEditField('address_city')" />
              <text-input label="State" v-model="state.form.address_state" :disabled="cannotEditField('address_state')" />
              <text-input label="Zip" v-model="state.form.address_zip" :disabled="cannotEditField('address_zip')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">hourglass_bottom</span>
          <h2 class="text-lg font-bold ml-1">Business Hours</h2>
        </div>
      </div>
      <div class="w-full p-4 xl:p-6">
        <div class="flex space-x-2">
          <div class="w-1/3">
            <time-picker label="Business Hours Start" v-model="state.form.business_hours_start" />
          </div>
          <div class="w-1/3">
            <time-picker label="Business Hours End" v-model="state.form.business_hours_end" />
          </div>
          <div class="w-1/3 flex items-center mt-6">
            {{ state.form.timezone || 'No Timezone Selected' }}
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">face</span>
          <h2 class="text-lg font-bold ml-1">Owner</h2>
        </div>
      </div>
      <div class="w-full md:w-1/2 flex flex-col space-y-2 p-4 xl:p-6">
        <autocomplete-text-input
          label="Owner"
          v-model="state.form.owner"
          :required="false"
          :disabled="cannotEditField('owner')"
          :suggestions="state.users"
        />
      </div>
    </div>

    <div class="bg-white shadow-sm rounded-md mt-6 xl:mt-8">
      <div class="border-b p-4 xl:px-6 flex items-center justify-between">
        <div class="flex items-center">
          <span class="material-icons text-big-stone">tune</span>
          <h2 class="text-lg font-bold ml-1">Settings</h2>
        </div>
      </div>
      <div class="w-full md:w-1/2 flex flex-col space-y-2 p-4 xl:p-6">
        <label class="text-sm font-semibold text-blumine">
          <input type="checkbox" v-model="state.form.bag_counts_enabled" :value="true" class="rounded text-blumine">
          <span class="ml-2">Bag Counts Enabled</span>
        </label>
      </div>
    </div>

    <div class="pt-6 xl:pt-8: text-right">
      <submit-button :is-loading="state.isSaving">Create</submit-button>
    </div>
  </form>
</template>

<script>
import { reactive, watch } from 'vue'
import notification from '@/services/notifications.js'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import { sneakCaseToReadable } from '@/helpers/utils'
import { machineModels } from '@/helpers/machine'
import TextInput from '@/components/form/Text.vue'
import AutocompleteTextInput from '@/components/form/AutocompleteText.vue'
import SelectBox from '@/components/form/Select.vue'
import TimePicker from '@/components/form/TimePicker.vue'
import Errors from '@/components/form/Errors.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import { IconLoading } from '@/components/icons'

export default {
  name: 'machines-create-machine-form',

  components: {
    TextInput,
    AutocompleteTextInput,
    SubmitButton,
    Errors,
    SelectBox,
    IconLoading,
    TimePicker,
  },

  props: {
    permissions: {
      type: Object,
      default: () => ({
        readonly_fields: []
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    timezoneOptions: {
      type: Array,
      required: true,
    },
    registerMappingOptions: {
      type: Array,
      required: true,
    }
  },

  emits: ['created'],

  setup(props, { emit }) {
    const state = reactive({
      form: {},
      errors: [],
      isSaving: false,
      users: [],
    })

    const cannotEditField = (field) => props.permissions.readonly_fields.includes(field)

    const create = async () => {
      state.errors = []
      state.isSaving = true

      try {
        const form = {
          bag_counts_enabled: false,
          ...state.form,
          business_hours_start: date.timeFormat(state.form.business_hours_start).forApi(),
          business_hours_end: date.timeFormat(state.form.business_hours_end).forApi(),
        }

        await http.post('machine', form)
        notification.success('Success', 'Machine has been created')
        emit('created', form)
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const getUsers = async () => {
      try {
        const { data } = await http.get('users')
        data.sort((a, b) => a.email.localeCompare(b.email))
        state.users = data.map(user => user.email)
      } catch (e) {
        notification.error('Error loading Users for Owner Autocomplete')
      }
    }
    
    watch(
      () => props.permissions,
      () => {
        if (props.permissions && !cannotEditField('owner')) {
          getUsers()
        }
      }
    )

    return {
      create,
      sneakCaseToReadable,
      state,
      cannotEditField,
      machineModels,
    }
  }
}
</script>
