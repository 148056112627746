<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-warning v-if="! state.hasAccess" title="Access denied">
      You are not allowed to create machines
    </alert-warning>

    <div v-else >
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">Add Machine</h2>
        <router-link :to="{ name: 'SettingsMachinesIndex'}" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="mt-6 xl:mt-8">
        <create-machine-form
          @created="redirectOnCreated"
          :is-loading="state.isInitialLoading"
          :permissions="state.permissions"
          :register-mapping-options="state.registerMappingOptions"
          :timezone-options="state.timezoneOptions"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Default.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import CreateMachineForm from '@/components/machines/CreateMachineForm.vue'
import { useRouter } from 'vue-router'
import SettingsNav from '@/components/settings/Nav'

export default {
  name: 'settings-machines-create',

  components: {
    Layout,
    AlertWarning,
    CreateMachineForm,
    SettingsNav,
  },

  setup() {
    const router = useRouter()
    const state = reactive({
      hasAccess: true,
      registerMappingOptions: [],
      timezoneOptions: [],
      isInitialLoading: false,
    })

    const checkCreatePermissionsAndLoadOptions = async () => {
      state.isInitialLoading = true

      try {
        const { data } = await http.get('machine')

        if (data.permissions.can_create) {
          state.hasAccess = true
          state.registerMappingOptions = data.register_mapping_options
          state.timezoneOptions = data.timezone_options
        } else {
          state.hasAccess = false
        }
      } catch (e) {
        if (e.response) {
          state.hasAccess = false
        } else {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }

      state.isInitialLoading = false
    }

    checkCreatePermissionsAndLoadOptions()

    const redirectOnCreated = () => {
      router.push({ name: 'SettingsMachinesIndex' })
    }

    return {
      state,
      redirectOnCreated,
    }
  }
}
</script>
